/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, message, Modal, Row } from "antd";
import DashboardCard from "Components/Card/DashboardCard";
import GridView from "Components/GridView/GridView";
import { cardList, userManagementColumns } from "./constants";
import "./UserManagement.less";
import { useQueryDashboardAnalytics, useQueryUpdateUser, useQueryUsers } from "queries/dashboard.queries";
import { PAGE_SIZE } from "queries/queries.constant";
import { useSelector } from "react-redux";
import { userTypes } from "util/util.constant";

const UserManagement = () => {
    const [page, setPage] = useState(1);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const search = useSelector((state) => state.search.search);
    let userQueryPayload = {
        page,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE,
        search: search
    };
    const { data: users, isLoading: userLoading, refetch: refetchUser } = useQueryUsers(userQueryPayload);
    const { data: analytics, isLoading: analyticsLoading } = useQueryDashboardAnalytics();
    const { mutateAsync: updateUserMutateQuery, isLoading: updateUserLoading } = useQueryUpdateUser();

    useEffect(() => {
        setPage(1);
        userQueryPayload = {
            page: 1,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search
        };
        refetchUser(userQueryPayload);
    }, [search]);

    const handleView = () => {
        console.log("viewing action");
    };

    const handleAction = () => {
        const payload = {
            status: parseInt(selectedUser?.user?.status) ? "0" : "1"
        };

        updateUserMutateQuery(
            {
                payload,
                userId: selectedUser?.id
            },
            {
                onSuccess: () => {
                    userQueryPayload = {};
                    message.success(
                        `${selectedUser?.user?.firstName} ${selectedUser?.user?.lastName} ${
                            parseInt(selectedUser?.user?.status) ? "blocked" : "unblocked"
                        }!`
                    );
                    setSelectedUser(null);
                    setModalVisibility(false);
                    refetchUser({
                        page,
                        isPagination: true,
                        type: userTypes.user,
                        take: PAGE_SIZE
                    });
                },
                onError: () => {
                    message.error("Unable to block the user");
                }
            }
        );
    };

    const columns = userManagementColumns(handleView, setModalVisibility, setSelectedUser);

    return (
        <div className="user-management-container">
            <div className="user-management-container__card-container">
                <Row gutter={[12, 12]}>
                    {cardList.map((cardInfo, index) => (
                        <Col lg={6} md={12} sm={12} xs={24} key={index}>
                            <DashboardCard
                              
                                cardInfo={cardInfo}
                                analyticsLoading={analyticsLoading}
                                analytics={analytics}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="user-management-container__table">
                <GridView
                    title={() => "Users"}
                    data={users?.data}
                    loading={userLoading}
                    columns={columns}
                    pagination={{
                        currentPage: users?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: users?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            <Modal
                className="user-management-container__modal"
                open={modalVisibility}
                centered
                onOk={handleAction}
                onCancel={() => setModalVisibility(false)}
                okButtonProps={{ loading: updateUserLoading }}
            >
                <div>
                    Are you sure you want to {parseInt(selectedUser?.user?.status) ? "block" : "unblock"}{" "}
                    {selectedUser?.user?.firstName} {selectedUser?.user?.lastName}?
                </div>
            </Modal>
        </div>
    );
};

export default UserManagement;
