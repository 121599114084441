import { useMutation, useQuery } from "react-query";
import { RefferalCodeService } from "services/reffferalCode.service";
import { globalReactQueryGetOption, QueryNames } from "./queries.constant";
import { useMemo } from "react";

function useMutationCreateRefferalCode() {
    const queryFn = async ({ payload }) => await RefferalCodeService.CreateRefferalCode(payload);
    return useMutation(queryFn);
}
function useQueryEligiblePlansList() {
    const queryName = [QueryNames.refferalCodeEligiblePlans];
    const queryFn = async () => await RefferalCodeService.EligiblePlansList();

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQueryRefferalList(payload) {
    const queryName = [QueryNames.getRefferalList, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await RefferalCodeService.RefferalList(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useMutationDeleteRefferalDetail() {
    const queryFn = async ({ id }) => {
        return await RefferalCodeService.deleteRefferalDetailItem(id);
    };
    return useMutation(queryFn);
}
function useQueryRefferalDetailByID(id,payload) {
    const queryName = [QueryNames.RefferalDetailByID, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await RefferalCodeService.RefferalDetailByID(id,queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useMutationEditRefferal() {
    const queryFn = async ({ payload, id }) => await RefferalCodeService.editRefferal(id, payload);
    return useMutation(queryFn);
}
export { useMutationCreateRefferalCode,useQueryEligiblePlansList,useQueryRefferalList,useMutationDeleteRefferalDetail,useQueryRefferalDetailByID,useMutationEditRefferal };
