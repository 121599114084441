import { Button } from "antd";
import GridView from "Components/GridView/GridView";
import SearchInput from "Components/SearchInput/SearchInput";
import { PAGE_SIZE } from "queries/queries.constant";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { authenticatedRoutesConstant, userTypes } from "util/util.constant";
import { CardViewDetail } from "../Components";
import "./SubscriptionPlansDetail.less";
import useSubscriptionPlansDetail from "./SubscriptionPlansDetailContainer";

function SubscriptionPlansDetail() {
    const { columns, search, setPage, additionalData, data, isLoading, refetch, handleSearch } =
        useSubscriptionPlansDetail();

    useEffect(() => {
        setPage(1);
        let userQueryPayload = {
            page: 1,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search
        };
        refetch(userQueryPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    return (
        <div className="subscription-plans-detail-container">
            <CardViewDetail
                actualValue={additionalData.actualPrice}
                credits={additionalData.credits}
                dateCreated={additionalData.date}
                discountPackage={additionalData?.discountPackage?.name}
                packageName={additionalData?.name}
                price={additionalData?.price}
                status={additionalData?.status}
                title={"Subscription Plan Details"}
            />
            <SearchInput onSearch={({ target }) => handleSearch(target.value)} />

            <div className="subscription-plans-detail-container__table">
                <GridView
                    title={() => "Subscription Plan"}
                    data={data?.data}
                    loading={isLoading}
                    columns={columns}
                    pagination={{
                        currentPage: data?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: data?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            <div className="dashboard-container__btn-container">
                <Link to={authenticatedRoutesConstant.SubscriptionPlans}>
                    <Button className="dashboard-container__btn-container__btn" type="primary">
                        Back
                    </Button>
                </Link>
            </div>
        </div>
    );
}
export default SubscriptionPlansDetail;

