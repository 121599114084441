import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
    selectedKey: "Dashboard"
};

export const searchSlice = createSlice({
    name: "searchParameters",
    initialState,
    reducers: {
        updateSearch: (state, { payload }) => {
            state.search = payload;
        },
        updateSelectedKey: (state, { payload }) => {
            state.selectedKey = payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateSearch, updateSelectedKey } = searchSlice.actions;

export default searchSlice.reducer;
