import { Row } from "antd";
import GridView from "Components/GridView/GridView";
import SearchInput from "Components/SearchInput/SearchInput";
import { PAGE_SIZE } from "queries/queries.constant";
import { useEffect } from "react";
import { userTypes } from "util/util.constant";
import { DeleteConfirmationModal, FilterAndCreate, PlanModal } from "./Components";
import { statusOptions } from "./constants";
import "./SubscriptionPlans.less";
import useSubscriptionPlansContainer from "./SubscriptionPlansContainer";

function SubscriptionPlans() {
    const {
        search,
        columns,
        setPage,
        page,
        deleteModalVisibility,
        handleDeleteModalConfirm,
        setDeleteModalVisibility,
        isModalVisible,
        handleCancel,
        handleFinish,
        initialValues,
        isEditing,
        handleFilterChange,
        handleCreatePlan,
        PackagesDataOptions,
        dataSubscriptionPlan,
        isLoading,
        refetchSubscriptionPlan,
        isLoading_CreatePlan,
        handleSearch,
        filterByStatus,
        PlantransformedOptions
    } = useSubscriptionPlansContainer();

    useEffect(() => {
        const userQueryPayload = {
            page,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search,
            status: filterByStatus
        };
        refetchSubscriptionPlan(userQueryPayload);
    }, [search, page, filterByStatus, refetchSubscriptionPlan]);

    return (
        <div className="subscription-plans-container">
            <Row justify={"space-between"} align={"middle"}>
                <SearchInput onSearch={({ target }) => handleSearch(target.value)} />
                <FilterAndCreate
                    statusOptions={statusOptions}
                    onFilterChange={handleFilterChange}
                    onCreatePlan={handleCreatePlan}
                />
            </Row>

            <div className="subscription-plans-container__table">
                <GridView
                    title={() => "Subscription Plan"}
                    data={dataSubscriptionPlan?.data}
                    loading={isLoading}
                    columns={columns}
                    pagination={{
                        currentPage: dataSubscriptionPlan?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: dataSubscriptionPlan?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            <DeleteConfirmationModal
                isVisible={deleteModalVisibility}
                onConfirm={handleDeleteModalConfirm}
                onCancel={() => setDeleteModalVisibility(false)}
            />
            <PlanModal
                isVisible={isModalVisible}
                onCancel={handleCancel}
                onFinish={handleFinish}
                initialValues={initialValues}
                isEditing={isEditing}
                subscriptionOptions={PackagesDataOptions}
                packagesOptions={PlantransformedOptions}
                isLoading={isLoading_CreatePlan}
            />
        </div>
    );
}

export default SubscriptionPlans;

