import { Button } from "antd";
import totalUsersIcon from "../../assets/all-svg-files/total-users-icon.svg";
import archiveIcon from "../../assets/all-svg-files/archive-icon.svg";
import tagIcon from "../../assets/all-svg-files/tag-icon.svg";

export const dashboardColumns = (handleClick) => {
    return [
        {
            title: "User ID",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Name",
            key: "name",
            render: (res) => <span>{`${res?.user?.firstName} ${res?.user?.lastName}`}</span>
        },

        {
            title: "Email",
            key: "email",
            render: (res) => <span>{res?.user?.email}</span>
        },

        {
            title: "City",
            key: "city",
            render: (res) => <span>{res?.user?.address?.city}</span>
        },

        {
            title: "State",
            key: "state",
            render: (res) => <span>{res?.user?.address?.state}</span>
        },

        {
            title: "Actions",
            key: "actions",
            render: (res) => (
                <Button type="primary" onClick={handleClick}>
                    View Details
                </Button>
            )
        }
    ];
};

export const dataSource = [
    {
        id: 2540,
        name: "Neal Matthews",
        email: "nathan.roberts@example.com",
        city: "Deshaunfurt",
        state: "New York"
    },
    {
        id: 2541,
        name: "Jamal Burnett",
        email: "alma.lawson@example.com",
        city: "Lake Lenna",
        state: "New Jersey"
    },
    {
        id: 2542,
        name: "Juan Mitchel",
        email: "bill.sanders@example.com",
        city: "Garthbury",
        state: "Alabama"
    },
    {
        id: 2543,
        name: "Barry Dick",
        email: "jackson.graham@example.com",
        city: "East Jovanny",
        state: "Indiana"
    },
    {
        id: 2544,
        name: "Ronald Taylor",
        email: "kenzi.lawson@example.com",
        city: "Mohrborough",
        state: "South Carolina"
    },
    {
        id: 2545,
        name: "Jacob Hunter",
        email: "willie.jennings@example.com",
        city: "Bernhardburgh",
        state: "Washington"
    }
];

export const cardList = [
    {
        title: "Total Users",
        key: "allUsers",
        count: "1235",
        icon: totalUsersIcon
    },

    {
        title: "Total Events",
        count: "351",
        key: "allEvents",
        icon: archiveIcon
    },

    {
        title: "Upcoming Events",
        count: "05",
        key: "allUpcomingEvents",
        icon: tagIcon
    },

    {
        title: "Forever After Events",
        count: "16",
        key: "allExpireEvents",
        icon: tagIcon
    }
];
