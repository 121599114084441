import { Button, Card, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
import dayjs from "dayjs";
import { useEffect } from "react";
import deleteIcon from "../../assets/all-svg-files/delete.svg";
import editIcon from "../../assets/all-svg-files/edit.svg";
import viewIcon from "../../assets/all-svg-files/view.svg";
import trashCan from "../../assets/all-svg-files/trash-can.svg";

const ActionIcons = ({ onView, onEdit, onDelete }) => {
    return (
        <div className="user-management-container__action-container">
            <img src={viewIcon} alt="view icon" onClick={onView} />
            <img src={editIcon} alt="edit icon" onClick={onEdit} />
            <img src={deleteIcon} alt="delete icon" onClick={onDelete} />
        </div>
    );
};
const CardViewDetail = ({ title, packageName, price, actualValue, discountPackage, credits, dateCreated, status }) => {
    const { Paragraph } = Typography;

    const leftColumn = [
        {
            id: 1,
            label: "Package Name:",
            value: packageName || "N/A"
        },
        {
            id: 2,
            label: "Price:",
            value: `$${price}` || "N/A"
        },
        {
            id: 3,
            label: "Actual Value:",
            value: `$${actualValue}` || "N/A"
        },
        {
            id: 4,
            label: "Discount Package:",
            value: discountPackage || "N/A"
        }
    ];
    const rightColumn = [
        {
            id: 1,
            label: "Credits:",
            value: credits
        },
        {
            id: 2,
            label: "Date Created:",
            value: dayjs(dateCreated).format("MM-DD-YYYY")
        },
        {
            id: 3,
            label: "Status:",
            value: parseInt(status) > 0 ? "Active" : "Inactive"
        }
    ];

    const style = {
        container: {
            marginBottom: 26
        },
        gridStyle: {
            minWidth: "50%",
            maxWidth: "100%"
        },
        label: {
            color: "#535353",
            fontWeight: "600"
        },
        value: {
            color: "#8A8989",
            fontWeight: "500"
        }
    };
    return (
        <div style={style.container}>
            <h1>Subscription Plan Details</h1>
            <Card style={style.gridStyle}>
                <Row gutter={16}>
                    <Col xs={24} xl={12}>
                        {leftColumn.map((item) => {
                            return (
                                <Row className="detail-row" justify={"space-between"} key={item.id}>
                                    <Paragraph style={style.label}>{item.label}</Paragraph>
                                    <Paragraph style={style.value}>{item.value}</Paragraph>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col xs={24} xl={12}>
                        {rightColumn.map((item) => {
                            return (
                                <Row className="detail-row" justify={"space-between"} key={item.id}>
                                    <Paragraph style={style.label}>{item.label}</Paragraph>
                                    <Paragraph style={style.value}>{item.value}</Paragraph>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

const DeleteConfirmationModal = ({ isVisible, onConfirm, onCancel }) => {
    return (
        <Modal
            className="subscription-plans-container__modal"
            open={isVisible}
            centered
            onOk={onConfirm}
            onCancel={onCancel}
            cancelText="No"
            okText="Yes"
        >
            <div className="deleteModal">
                <img src={trashCan} alt="Trash Can" />
                <p>Are you sure you want to delete this</p>
                <p>Subscription?</p>
            </div>
        </Modal>
    );
};

const PlanModal = ({
    isVisible,
    onCancel,
    onFinish,
    initialValues = {},
    isEditing,
    subscriptionOptions,
    packagesOptions,
    isLoading
}) => {
    const [form] = Form.useForm();
    const isDiscountedPackage = Form.useWatch("isDiscountedPackage", form);
    const isSubscription = Form.useWatch("IsSubscription", form);
    const GooglePurchaseID = Form.useWatch("google_purchase_id", form);
    const IOSPurchaseID = Form.useWatch("ios_purchase_id", form);

    const price = Form.useWatch("price", form);
    const actualPrice = Form.useWatch("actual_price", form);

    const validatePrice = (_, value) => {
        if (value < actualPrice) {
            return Promise.reject(new Error("Package Price should not be less than Actual Value"));
        }
        return Promise.resolve();
    };

    const validateActualPrice = (_, value) => {
        if (value > price) {
            return Promise.reject(new Error("Actual Value should not be greater than Package Price"));
        }
        return Promise.resolve();
    };

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };

    const handleSelectChange = (field) => (values) => {
        form.setFieldsValue({ [field]: values });
    };
    const handleCheckboxChange = (field) => (e) => {
        form.setFieldsValue({ [field]: e.target.checked });
    };

    const resetAndSetValue = () => {
        form.resetFields();
        form.setFieldsValue(initialValues);
    };

    useEffect(() => {
        resetAndSetValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, isEditing]);

    return (
        <Modal footer={null} open={isVisible} centered onCancel={handleCancel} width={700}>
            <div className="subscription-plans-container__modal">
                <h2 className="__editSubscriptionTitle">{isEditing ? "Edit Plan" : "Create New Plan"}</h2>
                <Form form={form} name="planForm" onFinish={onFinish} autoComplete="off" layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: "Please input the package name!" }]}
                                label="Package Name"
                            >
                                <Input placeholder="Enter Package Name" />
                            </Form.Item>
                        </Col>
                        {!isSubscription && (
                            <Col span={12}>
                                <Form.Item
                                    name="credits"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input total credits!"
                                        }
                                    ]}
                                    label="Total Credits"
                                    type="number"
                                >
                                    <Input placeholder="Enter Total Credits" type="number" />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name="google_purchase_id"
                                rules={[{ required: !IOSPurchaseID, message: "Please input Google Purchase ID!" }]}
                                label="Google Purchase ID"
                            >
                                <Input placeholder="Enter Google Purchase ID" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ios_purchase_id"
                                rules={[{ required: !GooglePurchaseID, message: "Please input iOS Purchase ID!" }]}
                                label="iOS Purchase ID"
                            >
                                <Input placeholder="Enter iOS Purchase ID" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="price"
                                rules={[
                                    { required: true, message: "Please input the package price!" },
                                    { validator: validatePrice }
                                ]}
                                label="Package Price"
                            >
                                <Input placeholder="Type a price" type="number" prefix="$" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="actual_price"
                                rules={[
                                    { required: false, message: "Please input the actual value!" },
                                    { validator: validateActualPrice }
                                ]}
                                label="Actual Value"
                            >
                                <Input placeholder="Actual Value" type="number" prefix="$" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="isDiscountedPackage"
                                valuePropName="checked"
                                className={isDiscountedPackage ? `checkBoxStyleCheck` : `checkBoxStyle`}
                            >
                                <CustomCheckbox
                                    onChange={handleCheckboxChange("isDiscountedPackage")}
                                    defaultChecked={initialValues.isDiscountedPackage || false}
                                >
                                    Discount Package
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {isDiscountedPackage && (
                                <Form.Item
                                    name="discount_package"
                                    label="Packages"
                                    rules={[
                                        { required: isDiscountedPackage ?? false, message: "Please select package" }
                                    ]}
                                >
                                    <Select
                                        defaultValue={initialValues.discount_package}
                                        className="SubscriptionDuration"
                                        placeholder="Select Packages"
                                        onChange={handleSelectChange("discount_package")}
                                        options={packagesOptions}
                                        showArrow
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="IsSubscription"
                                valuePropName="checked"
                                className={isSubscription ? `checkBoxStyleCheck` : `checkBoxStyle`}
                            >
                                <CustomCheckbox
                                    onChange={handleCheckboxChange("IsSubscription")}
                                    defaultChecked={initialValues.IsSubscription || false}
                                >
                                    Is Subscription
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {isSubscription && (
                                <Form.Item
                                    name="subscription_by"
                                    label="Subscription Duration"
                                    rules={[{ required: isSubscription ?? false, message: "Please select Duration" }]}
                                >
                                    <Select
                                        defaultValue={initialValues.subscription_by}
                                        className="SubscriptionDuration"
                                        placeholder="Select Duration"
                                        onChange={handleSelectChange("subscription_by")}
                                        options={subscriptionOptions}
                                        showArrow
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEditing ? "Update" : "Create"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

const FilterAndCreate = ({ filterLabel = "Filter By", statusOptions, onFilterChange, onCreatePlan }) => {
    const { Option } = Select;

    return (
        <Row align="middle" gutter={16} className="FilterAndCreateContainer">
            <Col>
                <span>{filterLabel}</span>
            </Col>
            <Col>
                <Select placeholder="Status" onChange={onFilterChange} style={{ width: 150 }}>
                    {statusOptions.map((status, index) => (
                        <Option key={index} value={status.value}>
                            {status.label}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col>
                <Button type="primary" style={{ backgroundColor: "#a89882" }} onClick={onCreatePlan}>
                    Create Plan
                </Button>
            </Col>
        </Row>
    );
};
export { ActionIcons, CardViewDetail, DeleteConfirmationModal, FilterAndCreate, PlanModal };

