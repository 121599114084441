import { DashboardService } from "services/dashboard.service";

const { useMemo } = require("react");
const { useQuery, useMutation } = require("react-query");
const { UserService } = require("services/users.service");
const { usePrefetchPaginationWithReactQuery } = require("./general.queries");
const { QueryNames, globalReactQueryGetOption, PAGINATION_STALE_TIME } = require("./queries.constant");

function useQueryUsers(payload) {
    const queryName = [QueryNames.users, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await UserService.getUsers(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        staleTime: !payload?.isPagination ? 0 : PAGINATION_STALE_TIME,
        keepPreviousData: true
    });

    usePrefetchPaginationWithReactQuery(
        queryReturnedValues,
        payload?.page,
        QueryNames.envelopes,
        () =>
            UserService.getUsers({
                ...queryFnParams,
                page: payload?.page + 1
            }),
        payload?.isPagination
    );

    return queryReturnedValues;
}

function useQueryDashboardAnalytics() {
    const queryName = [QueryNames.dashboard];
    const queryFn = async () => await DashboardService.getAnalytics();

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}

function useQueryUpdateUser() {
    const queryFn = async ({ payload, userId }) => await UserService.updateUser(userId, payload);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while updating the envelope" });
        // }
    });
}

export { useQueryUsers, useQueryDashboardAnalytics, useQueryUpdateUser };
