import React from "react";
import dashboardIcon from "../assets/all-svg-files/dashboard-icon.svg";
import userManagementIcon from "../assets/all-svg-files/user-management.svg";
import subscriptions from "../assets/all-svg-files/subscriptions.svg";
import Referral from "../assets/all-svg-files/referral.svg";

import { Link, Navigate } from "react-router-dom";
import { authenticatedRoutesConstant, unAuthenticatedRoutesConstant } from "util/util.constant";
import Login from "Container/Login/Login";
import Register from "Container/Register/Register";
import CustomLayout from "Components/CustomLayout/CustomLayout";
import Dashboard from "Container/Dashboard/Dashboard";
import UserManagement from "Container/UserManagement/UserManagement";
import { SubscriptionPlans, SubscriptionPlansDetail } from "Container/SubscriptionPlans";
import { RefferalCode } from "Container/RefferalCode";
import RefferalCodeDetail from "Container/RefferalCode/RefferalCodeDetail/RefferalCodeDetail";

export const sidebarItems = [
    {
        // icon: <UserOutlined />,
        icon: <img src={dashboardIcon} alt="dashboard-icon" />,
        label: <Link to={authenticatedRoutesConstant.Dashboard}>Dashboard</Link>,
        key: "Dashboard"
    },
    {
        // icon: <UserOutlined />,
        icon: <img src={userManagementIcon} alt="user-management-icon" />,
        label: <Link to={authenticatedRoutesConstant.UserManagement}>User Management</Link>,
        key: "UserManagement"
    },
    {
        // icon: <UserOutlined />,
        icon: <img src={subscriptions} alt="subscription-plans-icon" />,
        label: <Link to={authenticatedRoutesConstant.SubscriptionPlans}>Subscription Plans</Link>,
        key: "SubscriptionPlans"
    },
    {
        // icon: <UserOutlined />,
        icon: <img src={Referral} alt="subscription-plans-icon" />,
        label: <Link to={authenticatedRoutesConstant.RefferalCode}>Referral Code Mgmt.</Link>,
        key: "RefferalCode"
    }
];

export const sidebarKeys = {
    dashboard: "Dashboard",
    userManagement: "UserManagement",
    SubscriptionPlans: "SubscriptionPlans",
    RefferalCode: "RefferalCode"
};

export const AUTHENTICATED_ROUTES = [
    {
        path: authenticatedRoutesConstant.Home,
        element: <CustomLayout />,
        children: [
            {
                path: authenticatedRoutesConstant.Dashboard,
                element: <Dashboard />,
                index: true
            },
            {
                path: authenticatedRoutesConstant.UserManagement,
                element: <UserManagement />
            },
            {
                path: authenticatedRoutesConstant.SubscriptionPlans,
                element: <SubscriptionPlans />
            },
            {
                path: authenticatedRoutesConstant.SubscriptionPlansDetails,
                element: <SubscriptionPlansDetail />
            },
            {
                path: authenticatedRoutesConstant.RefferalCode,
                element: <RefferalCode />
            },
            {
                path: authenticatedRoutesConstant.RefferalCodeDetails,
                element: <RefferalCodeDetail />
            },
        ]
    },
    { path: "*", element: <Navigate to="/" replace /> }
];

export const UNAUTHENTICATED_ROUTES = [
    {
        path: unAuthenticatedRoutesConstant.Login,
        element: <Login />,
        index: true
    },
    {
        path: unAuthenticatedRoutesConstant.Register,
        element: <Register />
    },
    { path: "*", element: <Navigate to="/" replace /> }
];
